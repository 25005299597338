import { Navigate, createBrowserRouter, useNavigate } from 'react-router-dom';
import paths from './path';
import myCardsRoutes from '@core/pages/MyCards/routes';
import profileRoutes from '@core/pages/Profile/routes';
import { ErrorSection } from 'ni-ui-kit/dist/components/errorSection';
import { useTranslation } from 'react-i18next';

const NotFoundPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(paths.myCards);
  };

  return (
    <ErrorSection
      code="404"
      title={t('shared.pageNotFoundTitle')}
      message={t('shared.pageNotFoundText')}
      onClick={handleClick}
    />
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Navigate to={paths.myCards} />,
  },
  myCardsRoutes,
  profileRoutes,
  {
    path: '*',
    element: <NotFoundPage />,
  },
]);

export default router;
