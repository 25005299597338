import { ThemeConfig } from 'antd';

const niUiKitTheme = {
  colors: {
    main1: '#18181B',
    main2: '#28282A',
    main3: '#38383B',
    main4: '#49494C',
    main5: '#5B5B5E',
    main6: '#6E6E70',
    main7: '#818183',
    main8: '#959597',
    main9: '#A9A9AB',
    main10: '#BEBEBF',
    main11: '#D3D3D4',
    main12: '#E9E9E9',
    main13: '#FFFFFF',
    primary1: '#e64360',
    primary2: '#e8556f',
    primary3: '#ea687f',
    primary4: '#ec7a8e',
    primary5: '#ee8c9e',
    primary6: '#f09fad',
    primary7: '#f2b1bc',
    primary8: '#f4c3cc',
    primary9: '#f6d5db',
    primary10: '#f8e8eb',
    primary11: '#fcf4f5',
    primary12: '#fcf7f8',
    primary13: '#FFFFFF',
    secondary1: '#0060a8',
    secondary2: '#196fb0',
    secondary3: '#327fb8',
    secondary4: '#4b8ec1',
    secondary5: '#649ec9',
    secondary6: '#7dadd1',
    secondary7: '#96bcd9',
    secondary8: '#afcce1',
    secondary9: '#c8dbea',
    secondary10: '#e5eaef',
    secondary11: '#f2f5f7',
    secondary12: '#f7fafc',
    secondary13: '#FFFFFF',
    tertiary: '#FFFFFF',
    bg1: '#0060a8',
    bg2: '#f2f5f7',
    text1: '#313136',
    text2: '#808080',
    text3: '#b1b1b1',
    text4: '#0060a8',
    text5: '#e64360',
    text100: '#303030',
    additional1: '#53c4bb',
    additional2: '#abc8e9',
    additional3: '#FFFFFF',
    info1: '#1890ff',
    info2: '#91d5ff',
    info3: '#e6f7ff',
    error1: '#ff0000',
    error2: '#ff0000',
    error3: '#fff1f0',
    warning1: '#fa8701',
    warning2: '#ffe58f',
    warning3: '#fffbe6',
    success1: '#25bc86',
    success2: '#25bc86',
    success3: '#f6fffd',
    confirmButton: '#25bc86',
    declineButton: '#e64343',
    activeOperation: '#52c41a',
    blockedOperation: '#a30000',
    dormantOperation: '#fa8701',
    newOperation: '#9254de',
    closedOperation: '#404040',
    pendingOperation: '#cf1322',
    replacedOperation: '#002e5d',
  },
};

const theme: ThemeConfig = {
  cssVar: true,
  token: {
    colorPrimary: '#e64360',
    colorSuccess: '#e64360',
    colorWarning: '#fa8701',
    colorTextBase: '#313136',
    fontFamily: 'Gotham',
    colorTextTertiary: '#808080',
    colorPrimaryBorder: '#CDCDCE',
    colorPrimaryActive: '#e64360',
    colorPrimaryHover: '#e64360',
    colorPrimaryBg: '#F2F5F7',
    colorBgLayout: '#FFF',
    colorIcon: '#0060a8',
  },
  components: {
    Layout: {
      headerBg: '#ffffff !important',
      bodyBg: '#f6f6f6',
    },
    Menu: {
      itemColor: '#e64360',
      colorBgContainer: 'transparent',
    },
    Select: {},
    Button: {
      colorPrimaryActive: '#F7F2F6',
    },
  },
};

export default { theme, niUiKitTheme };
